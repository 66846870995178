import React from 'react'
import { PageProps, graphql, Link } from 'gatsby'
import Layout from 'src/components/Layout'
import SEO from 'src/components/Seo'
import { EssayListQuery } from 'types/graphql-types'

const EssayList: React.FC<PageProps<EssayListQuery>> = ({ data }) => {
  const items = data.essays.edges
  return (
    <>
      <SEO title="Essays" />
      <Layout>
        <ul className="text-lg">
          {items.map(item => (
            <li key={item.node.id} className="pb-8">
              <Link to={`./${item.node.uid!}`}>
                {item.node.data?.title?.text}
              </Link>
            </li>
          ))}
        </ul>
      </Layout>
    </>
  )
}

export const query = graphql`
  query EssayList($language: String!) {
    essays: allPrismicEssays(
      filter: { lang: { eq: $language } }
      sort: {
        fields: [data___featured, data___launch_date]
        order: [DESC, DESC]
      }
    ) {
      edges {
        node {
          id
          uid
          data {
            title {
              text
            }
          }
        }
      }
    }
  }
`

export default EssayList
